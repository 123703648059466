import { Conf } from './conf';
import { create_manufacturing_home_page } from '../common/ui';
import { rpc } from '../common/crystal_api/rpc';

export class SearchManufacturing extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.search_doc_classes = currents['classes'] || [];
  }

  is_search() {
    return true;
  }

  do_search(should_reset_query_hash = true) {
    if (should_reset_query_hash) {
      reset_queryHash();
    }
    globalThis.do_manufacturing_search();
  }

  search_elements_created() {}

  clear_search_doc() {
    console.log('Clear search');
    document.getElementById('search_in_home').value = '';
    rpc
      .doc({
        uid: 'def:Style#SearchManufacturing#0',
      })
      .then((response) => create_manufacturing_home_page(response, { force_initialization: true }))
      .then(() => this.do_search(true));
  }

  view(parent) {
    empty_element(parent);
    this.skip = ['name', 'comment', 'alternatives', 'results', 'limit', 'queryHash', 'qversions'];
    const options_descs = [];
    for (let handle of this.sections['main']) {
      if (this.options[handle].type === 'Section') {
        this.skip.push(handle);
      }
      if (!this.skip.includes(handle)) {
        const option_uuid = this.options[handle].create_template_option();
        options_descs.push(option_uuid);
      }
    }
    const manufacturing_search_template = require('../view/document/document_search.handlebars');
    const manufacturing_search_html = manufacturing_search_template({
      option_uuid_list: JSON.stringify({ option_uuid_list: options_descs }),
      conf_uuid: JSON.stringify(this.uuid),
    });
    parent.insertAdjacentHTML('beforeend', manufacturing_search_html);
  }
}

globalThis.manufaturing_classes ||= {};
globalThis.manufaturing_classes.SearchManufacturing = SearchManufacturing;
