import editor_css from '@toast-ui/editor/toastui-editor.css';

import * as XLSX from 'xlsx-js-style';
import { empty_element, remove_element, set_animate_pulse_classes, set_input_caret_to_end } from '../../common/ui';
import { add_controller, remove_controller } from '../../common/controllers';
import { show_ephemeral_toast } from '../../common/toast';
import { as_number_safe, get_unit, object_with_default } from '../../common/utils';

editor_css.use();

var types_default = {
  String: '',
  Float: 0,
  Integer: 0,
  Boolean: false,
  Chooser: '',
  DateTime: Date.now(),
  Table: [],
  Role: [],
  TextArea: '',
  Section: '',
};

class Option extends EventTarget {
  constructor(current, style, conf) {
    super();
    this.objid = globalThis.global_counter.next();
    add_controller(this);
    this.parent_element = null;
    this.current = current;
    if (current == null) {
      current = style.factory_default || types_default[style.type];
      this.current = current;
    }
    this.new_current = JSON.parse(JSON.stringify(current));
    this.style = style;
    this.handle = style['handle'];
    this.uid = style['handle'] + '_option_' + this.objid;
    this.attr = style['attr'];
    this.type = style['type'];
    this.name = style['name'];
    this.link = style['link'];
    this.readonly = false;
    this.input_edit = null;
    if (this.attr && this.attr.includes('ReadOnly')) {
      this.readonly = true;
    }
    if (conf && conf.uid == 'Comparison') {
      this.readonly = true;
    }
    if (['Section', 'Button', 'Meta'].includes(this.type)) {
      this.readonly = true;
    }
    if (conf && conf.uid.startsWith('User')) {
      this.readonly = true;
    }
    if (conf.readonly) {
      this.readonly = true;
    }
    this.widget = false;
    this.conf = conf;
    this.version = conf.is_version || false;
    this.hidden = false;
    const is_user = conf.is_user_view;
    const is_usercompany = conf.is_usercompany_view;
    const ignore_readonly = is_user || is_usercompany;
    this.readonly_view = this.readonly && !ignore_readonly;
    this.cursor_pointer = ignore_readonly ? false : !this.readonly_view;
    this.input_validator = this.style.validate || '.*';
    this.is_required = ['name', 'comment', 'email', 'password', 'captcha'].includes(this.handle) && this.conf.required_options;
    this.template_override = undefined;
    this.autocalc_option = Object.keys(this.style).includes('auto');
  }

  set_edit_mode(is_edit_mode) {
    this.readonly = !is_edit_mode;
    this.style.auto = is_edit_mode ? 'override' : 'auto';
    this.conf.log.modify_style({ auto: this.style.auto }, this.handle);
    this.update_view(is_edit_mode);
  }

  update_view(is_edit_mode) {
    if (!is_edit_mode) {
      this.set_new_current(undefined);
    } else {
      this.set_new_current(this.conf.log.get_first_value_of(this.handle));
    }
    this.refresh_view();
  }

  restore() {
    this.conf.log.undo(this.handle);
    this.current = this.conf.log.get_last_value_of(this.handle);
    this.new_current = this.current;
    this.refresh_view();
    dispatch_event('option_value_restored');
    return true;
  }

  is_editable_with_a_label_click() {
    return true;
  }

  is_edited_in_overlay() {
    return false;
  }

  has_grid_class() {
    return true;
  }
  show_current_table() {
    return false;
  }

  get_option_class() {
    return '';
  }

  do_table() {
    return false;
  }

  activate_section() {
    return true;
  }

  is_editing() {
    return document.getElementById(`${this.uid}_input`) != null;
  }

  is_search_genome() {
    return this.conf.class_name === 'SearchGenome';
  }

  add_map() {
    return false;
  }

  new_user_label() {
    return _(this.style.name);
  }

  is_password_field() {
    return this.handle.includes('Password') || this.handle.includes('password');
  }

  get_input_type() {
    let type = 'text';
    if (this.is_password_field()) type = 'password';
    if (this.handle === 'email') type = 'email';
    return type;
  }

  get_input_autocomplete() {
    return this.is_password_field() ? 'new-password' : 'off';
  }

  get_input_placeholder() {
    const placeholders = {
      phone: '+39 0123456789',
      email: 'yourname@yourcompany.com',
      password: 'Username@00',
    };
    const default_placeholder = `Insert ${this.name.toLowerCase()}`;

    return object_with_default(placeholders, default_placeholder)[this.handle];
  }

  get_input_value() {
    return this.current;
  }

  get_option_label() {
    let label = 'Click to edit';
    const is_search_option = this.conf && this.conf.class_name.includes('Search');
    if (is_search_option || this.readonly || !this.is_editable_with_a_label_click()) label = '';
    return label;
  }

  close() {
    if (this.p_label && this.p_label.parentNode) {
      empty_element(this.p_label.parentNode);
      this.p_label = null;
    }
    remove_controller(this);
  }

  current_to_user_view() {
    return this.current_to_user(this.conf.log.get_last_value_of(this.handle)) || globalThis.empty_data_placeholder;
  }

  should_show_content() {
    return true;
  }

  factory_default() {
    return this.autocalc_option ? this.style.factory_default : undefined;
  }

  value_to_user(value) {
    return value;
  }

  current_to_user(should_show_unit = true) {
    return this.value_to_user(this.conf.log.get_last_value_of(this.handle), should_show_unit);
  }

  value_from_user(value) {
    return value;
  }

  option_div_inline() {
    return true;
  }

  justify_current() {
    return 'justify-self-end';
  }

  inline_option() {
    return 'inline-block';
  }

  get_template_name() {
    return this.type.toLowerCase();
  }

  current_from_user() {
    const input_container = document.getElementById(`edit_${this.uid}`);
    const table_input_element = document.getElementById(`table_${this['handle']}_input`);
    if (!input_container && !table_input_element) {
      return this.current;
    }
    let input_value = document.getElementById(`${this.uid}_input`) || {};
    if (table_input_element) {
      input_value = table_input_element;
    }
    return this.value_from_user(input_value.value);
  }

  set_new_current(new_current) {
    this.conf.log.add_value(new_current, this.handle);
    this.current = new_current;
  }

  update_history() {
    if (!this.conf.is_support_page) {
      this.conf.log.add_value(this.current_from_user(), this.handle);
      this.conf.show_action_buttons();
      this.conf.value_changed();
    }
  }

  async apply_changes(should_refresh_view = true) {
    let new_current = this.conf.log.get_last_value_of(this.handle);
    if (new_current != null) {
      if (new_current !== this.current) {
        this.set_new_current(new_current);
      }
    }

    should_refresh_view && this.refresh_view();
    this.conf.log.commit(this.handle);

    const option_label = await globalThis.getElementByIdWithRetry(`${this.uid}_label`);
    option_label && set_animate_pulse_classes(option_label);
  }

  refresh_view() {
    let option_template = require('../../view/option/show_option.handlebars');
    if (this.template_override) {
      option_template = require(`../../${this.template_override}`);
    }
    const option_html = option_template({
      current_option_uuid: this.create_template_option(),
    });
    const node = document.createElement('div');
    node.innerHTML = option_html;
    document.getElementById(this.uid)?.parentNode.replaceWith(node.firstChild);
    this.input_edit = null;
    this.conf.show_action_buttons();
  }

  reset_default() {
    this.conf.log.add_value(this.style.factory_default, this.handle);
  }

  empty_currents_table() {
    this.table.clearData();
    this.table.addRow(this.table_new_row(true)[1], false);
    this.new_current = [];
    this._in_dest_table = {};
  }

  template_edit_buttons() {
    const section = this.style['section'] ? this.style['section'] : 'main';
    const empty_table_visible =
      ['Table', 'MultiRole'].includes(this.type) && this.handle != 'properties' && !section.startsWith('cmp_') ? true : false;

    return {
      show_empty_table: empty_table_visible,
      edit_buttons: !this.full_editing,
    };
  }

  is_option_editable() {
    if (this.readonly && (!this.style.auto || this.style.auto === 'auto')) {
      if (!this.full_editing) {
        show_ephemeral_toast(_('cannot-edit'), 'Info');
      }
      return false;
    }

    return true;
  }

  set_editing_status(full_editing) {
    this.full_editing = full_editing;
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      option_id: this.uid,
      current: this.current,
    };
    return [edit_option_desc, 'option/edit_option'];
  }

  input_element_enterkey() {
    if (!this.full_editing) {
      this.apply_changes();
    }
  }

  get_current_widget_id() {
    return `${this.uid}_widget`;
  }

  async edit_option_template(full_editing = false) {
    if (!this.is_option_editable()) return false;
    remove_element(`f_d_${this.uid}`);
    this.set_editing_status(full_editing);
    remove_element(`${this.uid}_edit_buttons`);
    let edit_buttons_desc = undefined;
    if (!this.full_editing) {
      edit_buttons_desc = this.template_edit_buttons();
    }
    const [input_desc, template] = this.template_input_edit_desc();
    input_desc['option_handle'] = this.handle;
    const edit_option_template_file = require(`../../view/${template}.handlebars`);
    const template_values = Object.assign({}, edit_buttons_desc, input_desc);
    if (this.handle === 'title' || this.handle === 'nameComment') {
      template_values.model_value = 'fast_search_value';
    }
    const edit_option_html = edit_option_template_file(template_values);
    const node = document.createElement('div');
    node.innerHTML = edit_option_html;

    const element = await globalThis.getElementByIdWithRetry(this.get_current_widget_id());

    element.replaceWith(node.firstChild);

    let input_element = await globalThis.getElementByIdWithRetry(`${this.uid}_input`);

    if (this.type === 'String' && !this.full_editing) {
      input_element.focus();
      set_input_caret_to_end(input_element);
    }

    return true;
  }

  add_widget_listener() {
    return {};
  }

  create_template_option() {
    return this.uuid;
  }

  save_edits() {
    this.current = this.current_from_user();
    return this.current;
  }

  get_export_option_value() {
    return this.is_editing() ? this.current_from_user() : this.current_to_user(false);
  }

  fill_cell(sheet, r) {
    XLSX.utils.sheet_add_aoa(
      sheet,
      [[{ v: _(this.name), s: { font: { bold: true } } }, as_number_safe(this.get_export_option_value()), { v: get_unit(this.style['unit']) }]],
      { origin: 'A' + r }
    );
    r = r + 1;
    return r;
  }

  set_table_input(editor) {
    const opt_edit = document.createElement('div');
    const table_opt_template = require('../../view/option/currents/option_in_tables.handlebars');
    const table_opt_html = table_opt_template({ handle: this.handle, value: this.current });
    opt_edit.insertAdjacentHTML('beforeend', table_opt_html);
    editor.input_edit = opt_edit;
    return opt_edit;
  }

  get_option_href_from_link() {
    return `${location.origin}/${get_document_hash(this.link, true)}`;
  }
}

function build_option(current, style, conf = undefined, original = undefined) {
  if (!style) {
    return false;
  }
  let type = style['type'];
  const attr = style['attr'];

  let opt = false;
  if (type === 'Chooser' && attr.includes('MultiChooser')) {
    if (style['handle'] === 'classes') {
      type = 'OptionMultiChooserClasses';
    } else {
      type = 'OptionMultiChooser';
    }
  }

  if (globalThis.option_types[type]) {
    opt = new globalThis.option_types[type](current, style, conf, original);
  } else {
    console.log('Unknown Option type', type);
  }
  return opt;
}

export { build_option, Option };
