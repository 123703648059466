import * as echarts from 'echarts';
import * as commands from './plot_commands';

import {
  get_chart_container_id,
  get_meta_markpoints,
  get_plot_datasets,
  get_series_list,
  get_temperature_y_axis,
  get_time_based_plot_ynames,
  get_toolbox,
  get_value_axis,
  get_x_axis,
  get_ynames,
  test_dataset,
} from './plot_functions';
import { capitalize_string, format_number } from '../common/utils';
import { empty_element } from '../common/ui';

const plot_attrs = {};

function set_plot_attrs(doc, plot) {
  plot_attrs['base_doc'] = doc;
  plot_attrs['is_model'] = !!doc.is_model;
  plot_attrs['div_id'] = get_chart_container_id(doc.class_name);
  plot_attrs['config'] = doc.options.chartConfig;
  plot_attrs['class_name'] = doc.class_name;
  const datasets = get_plot_datasets(doc.styles).filter((d) => d['plot'] === plot);
  plot_attrs['datasets'] = datasets;
  plot_attrs['temp_plot_datasets'] = datasets.filter((d) => !test_dataset('t', d['handle']));
  const initial_dimensions = {};
  datasets
    .filter((d) => d['initialDimension'] || ['micron', 'percent'].includes(d['unit']))
    .forEach((d) => {
      const i_d = d['initialDimension'] || d['dataset'].slice(0, 10).reduce((acc, val) => acc + val, 0) / 10;
      initial_dimensions[d['handle']] = { default_unit: d['unit'], initial_dimension: i_d, csunit: d['csunit'] };
    });
  plot_attrs['initial_dimensions'] = initial_dimensions;
  const options = {};
  datasets.map((d) => (options[d['handle']] = doc.options[d['handle']]));
  plot_attrs['options'] = options;
  const units = {};
  datasets.map((d) => (units[d['handle']] = options[d['handle']]['style']['unit'] || ''));
  plot_attrs['units'] = units;
  plot_attrs['markpoints'] = {};
}

function empty_datasets(doc, plot) {
  const datasets = get_plot_datasets(doc.styles).filter((d) => d['plot'] === plot);
  const ret = datasets.map((d) => d['dataset']).reduce((tot, curr) => tot + curr.length, 0);
  return ret === 0;
}

function show_series_percentages(chart) {
  const series = chart.getOption()['series'];
  const changed_units = [];
  series.forEach((s) => {
    const i_d = plot_attrs['initial_dimensions'][s['name']];
    if (i_d && ['micron', 'micron^3'].includes(i_d['default_unit']) && i_d['csunit'] === 'percent') {
      s['data'].map((c) => (c[1] = (c[1] / i_d['initial_dimension']) * 100));
      changed_units.push(i_d['default_unit']);
    }
  });
  const y_axis = chart.getOption()['yAxis'];
  const new_y_axis = y_axis.map((ax) => {
    if (changed_units.includes(icvt[ax['unit']])) {
      const new_axis = get_value_axis('percent', { name: ax['name'] });
      new_axis['position'] = ax['position'];
      if (new_axis['name'] === 'Micron') new_axis['name'] = 'Percent';
      return new_axis;
    } else {
      return ax;
    }
  });
  chart.setOption({ series: series, yAxis: new_y_axis }, { replaceMerge: ['series', 'yAxis'] });
}

function get_yAxis_list(ynames, test_string) {
  const yAxis_datasets = plot_attrs['temp_plot_datasets'].filter((d) => !test_dataset(test_string, d['handle']) && ynames.includes(d['handle']));
  const yAxis = [];
  if (yAxis_datasets.length === 1) {
    yAxis.push(get_value_axis(plot_attrs['is_model'] ? 'percent' : 'micron', yAxis_datasets[0]));
  } else {
    multi_y_axis(yAxis_datasets, yAxis);
  }
  return yAxis;
}

function multi_y_axis(datasets, yAxis) {
  const axis_desc = {};
  const datasets_units = datasets.map((d) => d['unit']);
  datasets.forEach((d, idx) => {
    const new_axis = get_value_axis(d['unit'], d);
    if (!axis_desc[d['unit']]) {
      axis_desc[d['unit']] = { index: idx, position: Object.keys(axis_desc).length ? 'right' : 'left' };
      new_axis['position'] = axis_desc[d['unit']]['position'];
      if (datasets_units.filter((i) => i === d['unit']).length > 1) {
        plot_attrs['units'][d['unit']] = d['unit'];
        new_axis['name'] = capitalize_string(d['unit']);
      }
      yAxis.push(new_axis);
    }
  });
}

function get_plot_options(yAxis, series, x_axis_name = 'Temperature') {
  return {
    tooltip: {
      trigger: 'axis',
      triggerOn: 'mousemove',
      formatter: (params) => {
        let tip = `${format_number(params[0]['axisValue'], '.4g')}<br/>`;
        params.forEach((line) => {
          tip += `
          <i class="fa-solid fa-circle fa-xs" style="color: ${line['color']}"></i>
          <b>${line['seriesName']}:</b> ${format_number(line['data'][1], '.3g')}<br/>`;
        });
        return tip;
      },
    },
    legend: { show: true },
    xAxis: get_x_axis(x_axis_name, x_axis_name === 'Temperature' ? 'celsius' : 'second'),
    yAxis: yAxis,
    series: series,
  };
}

function model_plot(doc, plot = undefined, clear = true) {
  clear && empty_element(document.getElementById(get_chart_container_id(doc.class_name)));
  if (empty_datasets(doc, plot)) return false;
  set_plot_attrs(doc, plot);
  if (!plot) plot = 'Main';
  config_temp_plot(plot);
  config_time_plot(plot);
}

function check_empty_series(series) {
  map();
}

function config_temp_plot(plot) {
  const plot_name = `${plot}_Temperature`;
  const xnames = plot_attrs['temp_plot_datasets'].filter((d) => test_dataset('T', d['handle'])).map((d) => d['handle']);
  const ynames = get_ynames(plot_attrs['temp_plot_datasets']);
  const yAxis = get_yAxis_list(ynames, 'T');
  const series = get_series_list(plot_attrs['options'], xnames, ynames, 'T', yAxis);
  const sum = series.reduce((acc, curr) => acc + curr.data.length, 0);
  if (sum == 0) {
    console.log('Empty plot:', plot_name);
    return plot_name;
  }
  const points = series.map((s) => s['data']);
  ynames.forEach((key, idx) => {
    plot_attrs['markpoints'][plot_name] = plot_attrs['markpoints'][plot_name] || {};
    plot_attrs['markpoints'][plot_name][key] = get_meta_markpoints(plot_attrs['base_doc'], plot, ynames, key, plot_attrs['options'], points[idx]);
  });
  show_plot(get_plot_options(yAxis, series), plot_name);
  return plot_name;
}

function config_time_plot(plot) {
  const plot_name = `${plot}_Time`;
  const xnames = plot_attrs['datasets'].filter((d) => test_dataset('t', d['handle'])).map((d) => d['handle']);
  const ynames = get_time_based_plot_ynames(plot_attrs['datasets']);
  const yAxis = get_yAxis_list(ynames, '[T,t]');
  const series = get_series_list(plot_attrs['options'], xnames, ynames, 't', yAxis);
  const sum = series.reduce((acc, curr) => acc + curr.data.length, 0);
  if (sum == 0) {
    console.log('Empty plot:', plot_name, sum, series);
    return plot_name;
  }
  const temp_axis = get_temperature_y_axis();
  if (yAxis.length > 1) {
    temp_axis['axisLine'] = { onZero: false };
    temp_axis['offset'] = 30 * yAxis.length;
  }
  yAxis.push(temp_axis);
  const temp_axis_index = yAxis.indexOf(yAxis.filter((ax) => ax.name === 'Temperature')[0]);
  series.forEach((s) => {
    if (test_dataset('T', s['name'])) {
      s['yAxisIndex'] = temp_axis_index;
    }
  });
  const points = series.map((s) => s['data']);
  ynames.forEach((key, idx) => {
    plot_attrs['markpoints'][plot_name] = plot_attrs['markpoints'][plot_name] || {};
    plot_attrs['markpoints'][plot_name][key] = get_meta_markpoints(plot_attrs['base_doc'], plot, ynames, key, plot_attrs['options'], points[idx]);
  });
  show_plot(get_plot_options(yAxis, series, 'Time'), plot_name);
  return plot_name;
}

function show_plot(opt, plot) {
  const plot_container = plot_divs(plot);
  const chart = echarts.init(plot_container, 'light_plot', { renderer: 'svg' });
  const command = new commands.PlotCommands(plot_attrs, chart, plot);
  opt['toolbox'] = get_toolbox(plot_attrs['base_doc'], command);
  chart.setOption(opt, true);
  show_series_percentages(chart);
  chart_actions(chart, command);
  command.load();
}

function plot_divs(plot_name) {
  const element = document.getElementById(plot_attrs['div_id']);
  const plot_container = document.createElement('div');
  plot_container.id = `plot_${plot_name}_container`;
  plot_container.classList.add('border-2', 'border-black', 'h-80');
  const legend_div = document.createElement('div');
  legend_div.id = `plot_${plot_name}_legend`;
  legend_div.classList.add('flex', 'space-x-8');
  element.appendChild(plot_container);
  element.appendChild(legend_div);
  return plot_container;
}

function chart_actions(chart, command) {
  chart.on('click', (params) => {
    const axis_value = params['componentType'].at(0);
    const axis_params = `${axis_value}AxisIndex`;
    params.componentType === command['series_click_functions']['new_markpoint'](() => command.add_markpoint_to_line(params));
    
    if (params.componentType === 'markPoint') {
      command.edit_markpoint(params);
    } else if (params.componentType.endsWith('Axis') && params.targetType === 'axisName') {
      const unit = icvt[chart.getOption()[`${axis_value}Axis`][params[axis_params]]['unit']];
      command.change_axis_unit(params, unit, axis_value);
    }
  });
}

export { model_plot };
