import { Option } from './option';

class OptionUrl extends Option {
  get_template_name() {
    return 'url';
  }

  get_url() {
    return this.current[0];
  }

  get_description() {
    return this.current[1];
  }
}

globalThis.option_types['Url'] = OptionUrl;
