import { dateToISO } from '../../common/ui';
import { Option } from './option';
import { max_date_timestamp, min_date_timestamp } from './option_ranges_dates_rounding';

class OptionRange extends Option {
  get_template_name() {
    return 'range';
  }

  get_current_widget_id() {
    return `${this.uid}_range_widget`;
  }

  value_to_user(value) {
    return value;
  }

  get_min_element() {
    return document.getElementById(`${this.uid}_input_min`).valueAsNumber;
  }

  get_max_element() {
    return document.getElementById(`${this.uid}_input_max`).valueAsNumber;
  }

  get_validated_new_current(min, max) {
    return [min || undefined, max || undefined];
  }

  value_from_user() {
    if (!document.getElementById(`edit_${this.uid}`)) {
      return this.current;
    }
    const min = this.get_min_element();
    const max = this.get_max_element();
    if (this.validate_min_max(min, max)) {
      this.new_current = this.get_validated_new_current(min, max);
      return this.new_current;
    } else {
      return { error: 'Field ' + this.name + ', value error' };
    }
  }

  validate_min_max(min, max) {
    if (!min) min = this.current_to_user_view()[0];
    if (!max) max = this.current_to_user_view()[1];
    if (max < min && min != undefined && max != undefined) {
      return false;
    }
    if (min != 0 && max != 0 && min != undefined && max != undefined) {
      if (!parseFloat(min) || !parseFloat(max)) {
        return false;
      }
    }
    return true;
  }

  current_to_user_view() {
    const from = this.current_to_user()[0] || undefined;
    const to = this.current_to_user()[1] || undefined;
    return [from, to];
  }

  get_range_type() {
    return this.type === 'RangeDate' ? 'date' : 'number';
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      inline: this.conf.uid === 'def:Style#SearchGenome#0',
    };
    return [edit_option_desc, 'option/edit_option_range'];
  }
}

class OptionRangeDate extends OptionRange {
  validate_min_max(min, max) {
    if (!min) min = new Date(0);
    if (!max) max = new Date();
    return Date.parse(max) > Date.parse(min);
  }

  get_min_element() {
    return document.getElementById(`${this.uid}_input_min`).valueAsDate;
  }

  get_max_element() {
    return document.getElementById(`${this.uid}_input_max`).valueAsDate;
  }

  get_validated_new_current(min, max) {
    if (!min) min = new Date(0);
    if (!max) max = new Date();
    return [min_date_timestamp(min) || undefined, max_date_timestamp(max) || undefined];
  }

  value_to_user(value) {
    const dateFrom = dateToISO(new Date(value[0] * 1000));
    const d = value[1] === 0 ? new Date() : new Date(value[1] * 1000);
    const dateTo = dateToISO(d);
    return [dateFrom, dateTo];
  }
}

globalThis.option_types['RangeDate'] = OptionRangeDate;
globalThis.option_types['RangeInteger'] = OptionRange;
globalThis.option_types['RangeFloat'] = OptionRange;
